import React, { useState } from 'react';


import Incoming from '../incoming/Incoming';
import constants from '../../Constants';

export default function Dashboard() {
  const [expanded, setExpanded] = useState(false);
  const [value, setValue] = useState(1);
  const [buying, setBuying] = useState(false);
  const [rozNamcha, setRozNamcha] = useState(true);
  const [selling, setSelling] = useState(false);
 
  const handleTabChange = (event, newValue) => {
    console.log(newValue)
    setBuying(false)
    setRozNamcha(false)
    setSelling(false)
    setValue(newValue);
    if(newValue == 0){
      setBuying(true)
    }
    else if(newValue == 1){
      setRozNamcha(true)
    }
    else if(newValue == 2){
      setSelling(true)
    }
  };

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div>
        <div>
            <Incoming expanded={expanded} />     
        </div>
    </div>
  );
}