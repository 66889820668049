const constants = {
    
    currency: 'افغانی',
    saveBtn:'ثبت',
    loan:'پور',
    cash:'نغد',
    transaction: 'معامله',
    cashAndLoan:'پور و نغد',
    billShortInfo:'بل جزیات',
    billedDate: 'دبل تاریخ',
    bill: 'بل ',
    getDate: 'تاریخ',
    income: 'جمع +',
    outcome: 'منفی -',
    billInfo: 'معلومات',
    balance: 'بیلینس',
    detailsIn: 'تفصیل امد',
    description: 'تفصیل ',
    detailsOut: 'تفصیل رفت',
    oldRoznamcha: ' دپرون پاتي  ',
    currRoznamcha: ' نن روزنامچه ',
    outRoznamcha: 'مصرف',
    inRoznamcha: ' عاید ',
    toastSuccessMsg: 'Record Successfull',
    toastErrorMsg: 'Error',
    subtracting: 'کمول',
    adding: 'زیاتول',
    addingDescription: 'دتفصیل لکنه',
    alertNumericMsg: 'صرف دانګیزی نمري',
    errorMsg: 'پاسورډ غلط دی',
    accounts:{
        mobileNo: '  موبایل شمیره ',
        personName : 'نوم',
        personAdd : 'ادرس',
        personRef : 'شناخته',
        personDues : 'بقایه',
    },
    dashboard:{
        buy: 'خرید',
        roznamcha : 'روزنامچه',
        sell: 'فروش ',
        itemPurchas: 'د جنس خریداري',
        itemPurchasInfo: 'د جنس معلومات',
        expense: 'مصرف',
        income: 'عاید',
        billCreation: 'بل جوړول',
        billInfo: 'بل معلومات',
        accountsCreate: 'کهاته جوړول',
        accountsInfo: 'کهاته معلومات'
    },
    purchase:{
        seller: 'خرصونکی',
        tellNo: 'ټبلیفون شمیره',
        bill: 'بل شمیره',
        itemNo: 'جنس تعداد',
        price: 'قیمت دانه',
        itemName: 'دجنس نوم',
        totalMoney: 'ټوټل افغانی ',
        totalItem: 'ټوټل جنس ',
    },
    billBook:{
        clientSelect: 'کهاته انخاب',
        
    },
}
export default constants;
