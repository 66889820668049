import React from 'react';
import { ScaleLoader } from 'react-spinners';

const Loader = ({ show }) => {
  const loaderStyle = {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 9999, // Ensure it's on top of everything
    backgroundColor: 'rgba(255, 255, 255, 0.7)', // White background with transparency
    width: '100%',
    height: '100%',
    display: show ? 'flex' : 'none',
    justifyContent: 'center',
    alignItems: 'center',
  };

  const overrideStyle = {
    display: 'block',
    margin: '0 auto',
  };

  return (
    <div style={loaderStyle}>
      <ScaleLoader
        color="#1c69fd"
        loading={show}
        css={overrideStyle} // Use `css` instead of `cssOverride`
        size={150}
        aria-label="Loading Spinner"
        data-testid="loader"
      />
    </div>
  );
};

export default Loader;
