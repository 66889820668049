export const FormatDate = (timestamp) => {
    const date = new Date(timestamp);      
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Month is zero-indexed, so we add 1
    const year = date.getFullYear();    
    // console.log(date)  
    return `${day}/${month}/${year}`;
  };
  
  export const compareDate = (right, left) =>{
    const rightDate = parseDate(right);
    const leftDate = parseDate(left);
      // console.log(rightDate, leftDate)
    let rightLeft = ''
    if (rightDate > leftDate) {
        rightLeft = 'right'
        // console.log('The date "right" is later than the date "left".');
    } else if (rightDate < leftDate) {
      rightLeft = 'left'
        // console.log('The date "right" is earlier than the date "left".');
    } else {
        rightLeft = 'same'
        // console.log('The dates are the same.');
    }
    return rightLeft
  }
  
  const  parseDate = (dateString) => {
    const [day, month, year] = dateString.split('/').map(Number);
    return new Date(year, month - 1, day); // Month is 0-indexed in JavaScript Date
  }