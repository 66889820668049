import React from 'react';

// Example functional component
const Logo = ({ imageUrl, altText }) => {
  return (
    <div className="image-container">
      <img src={imageUrl} alt={altText} />
    </div>
  );
};

export default Logo;
