import React, { useEffect, useState } from 'react';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import constants from '../../Constants';
import db from "../../utils/Utility";
import { Box, Button, TextField} from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import { compareDate, FormatDate } from '../../utils/DateFormats';
import { toast } from 'react-toastify';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import 'dayjs/locale/en-gb';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import DeleteIcon from '@mui/icons-material/Delete';
import AddBoxIcon from '@mui/icons-material/AddBox';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const Incoming = ({ expanded }) => {
  const theme = useTheme();
  const matchesSm = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();
  const [expired, setExpired] = useState(false)

  const [selectedIncome, setSelectedIncome] = useState(0);
  const [selectedOutcome, setSelectedOutcome] = useState(0);
  const [dataIncome, setDataIncome] = useState([]);
  const [dataOutcome, setDataOutcome] = useState([]);
  const [incomeValue, setIncomeValue] = useState();
  const [outcomeValue, setOutcomeValue] = useState();
  const [oldRoznamchaIn, setOldRoznamchaIn] = useState(0);
  const [oldRoznamchaOut, setOldRoznamchaOut] = useState(0);
  const [currRoznamchaIn, setCurrRoznamchaIn] = useState(0);
  const [currRoznamchaOut, setCurrRoznamchaOut] = useState(0);
  const [totalTodayRoznamcha, setTotalTodayRoznamcha] = useState(0);
  const [selectedDate, setSelectedDate] = useState(dayjs());
  const [todayDate, setTodayDate] = useState(dayjs());
  const [noInsertion, setNoInsertion] = useState(false); //outcomeDescription
  const [outcomeDescription, setOutcomeDescription] = useState('');
  const [incomeDescription, setIncomeDescription] = useState('');
  const [outDesShow, setOutDesShow] = useState(false);
  const [inDesShow, setInDesShow] = useState(false);
  const [currency, setCurrency] = useState([]);
  const [account, setAccount] = useState(1);
  const [accCurrency, setAccCurrency] = useState('افغانی');
  const [login, setLogin] = useState(localStorage.getItem('Logged'));
  const [uploadData, setUploadData] = useState();
  const [uploadDateTime, setUploadDateTime] = useState();
  const [otp, setOtp] = useState('');
  const [otpErrorMsg, setOtpErrorMsg] = useState('');
  const [selectedCurrencyId, setSelectedCurrencyId] = useState(1);


  const [fetchRoznamcha, setFetchRoznamcha] = useState([]);
  const [roznamchaValues, setRoznamchaValues] = useState({
    account: account, payIn: 0, payOut: 0,  date: '', description: '',
  })
  const handleDateChange = (newDateSelect) => {
    // console.log(newDateSelect)
    setSelectedDate(newDateSelect);
  };
  async function fetchData() {
    try {
      const d = Date.now();
      const getDate = FormatDate(selectedDate)
      const allCurrencies = await db.getAll('Currency');
      setCurrency(allCurrencies)
      // const allOutcome = await db.getAll('PurchasedFrom');
      const fetchRoznamcha = await db.getAll('RozNamchaBook');
      setUploadData(fetchRoznamcha)
      const roznamcha = fetchRoznamcha.filter(e => e.account === account)
      const filterOutRoznamchaByDate = roznamcha.filter(day => day.date === getDate )
      console.log(filterOutRoznamchaByDate);
      setDataIncome(roznamcha);
      const filterOutAccounts = filterOutRoznamchaByDate //?.find(option => option?.id === account);
      setFetchRoznamcha(filterOutAccounts)
      let oldPayIn = 0
      let currPayIn = 0
      let oldPayOut = 0
      let currPayOut = 0
      filterOutAccounts.filter((opt) => {
        
        if (opt.payIn == 0) {
          // console.log(opt)
          currPayOut = currPayOut + parseInt(opt.payOut)
        }
        if (opt.payOut ==  0) {
          // console.log(opt, opt.purchaseSrn)
          currPayIn = currPayIn + parseInt(opt.payIn)
        }
        // console.log(currPayIn)
      })
      roznamcha.filter((opt) => {
        compareDate(opt.date, getDate)
        if (opt.payOut == 0  && compareDate(opt.date, getDate) == 'left') {
          console.log(opt)
          oldPayIn = oldPayIn + parseInt(opt.payIn)
          console.log(compareDate(opt.date, getDate))
        }
        if (opt.payIn == 0 && compareDate(opt.date, getDate) == 'left') {
          oldPayOut = oldPayOut + parseInt(opt.payOut)
        }
      }); 
      setOldRoznamchaIn(oldPayIn)
      const showInRoz = 0
      setCurrRoznamchaOut(currPayOut)
      setTotalTodayRoznamcha(((oldPayIn - oldPayOut) + currPayIn) - currPayOut)
      if (oldPayIn - oldPayOut > 0) {
        // console.log('-----1', oldPayIn, oldPayOut, currPayIn)
        setOldRoznamchaIn(oldPayIn - oldPayOut)
        setOldRoznamchaOut(0)
        setCurrRoznamchaIn((oldPayIn - oldPayOut) + currPayIn)
      }
      else if (oldPayIn - oldPayOut == 0) {
        // console.log('-----2')
        setCurrRoznamchaIn((oldPayIn - oldPayOut) + currPayIn)

        setOldRoznamchaIn(0)
        setOldRoznamchaOut(0)
      }
      else {
        console.log('-----3')

        alert('please take screen shot and contact Wasily Technologies 0703131865')
      }
      // console.log(currRoznamchaIn, currRoznamchaOut)
      // setOldRoznamchaOut(oldPayOut)
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }
  const handleFocus = () => {
    console.log(login)
    if(login === false || login === null || login === undefined  || login === '')
      navigate('/login')
      
    fetchData();
    if (compareDate(FormatDate(todayDate), FormatDate(selectedDate)) == 'same')
      setNoInsertion(true)
    else
      setNoInsertion(false)
      console.log(compareDate(FormatDate(todayDate), FormatDate(selectedDate)) == 'same')
    }
  useEffect(() => {
    checkExpiry()
    handleFocus(); // Run on mount
    //window.addEventListener('focus', handleFocus); // Add event listener for focus
    return () => {
      //window.removeEventListener('focus', handleFocus); // Cleanup event listener on unmount
    };
  }, [selectedDate, account, login]);

  const checkExpiry = async () => {
    try {
        const currentDate = new Date(); // Current date
        const getProfile = await db.getAll('Profile');
        const expDateStr = getProfile[0].activationDate; // Expiry date in format dd/mm/yyyy
        const addMonth  = getProfile[0].months

        // Function to parse date from dd/mm/yyyy format
        const parseDate = (dateStr) => {
            const [day, month, year] = dateStr.split('/').map(Number);
            return new Date(year, month - 1, day); // Months are 0-indexed in JavaScript Date
        };

        // Function to add months to a date
        const addMonths = (date, months) => {
            const d = new Date(date);
            d.setMonth(d.getMonth() + months);
            return d;
        };

        // Parse the expiration date
        const expDate = parseDate(expDateStr);

        // Add months to the expiration date
        const updatedExpDate = addMonths(expDate, addMonth);

        // Compare the updated expiration date with the current date
        if (updatedExpDate < currentDate) {
            // Show popup message if the updated date is expired
            alert('The activation date has expired.');
            setExpired(true)
            console.log(expired)
            //return 'expired'
        } else {
            console.log('The activation date is still valid.', expired);
            setExpired(false)
        }
    } catch (error) {
        console.error('Error checking expiry:', error);
    }
  };
 


  const logout =  () => {
    setLogin(false)
  }

  const updateIncome = (event) => {
    const value = event.target.value;
    if (isNaN(value) || value.trim() === '' || Number(value) < 0) {
      alert(constants.alertNumericMsg);
      setIncomeValue('')
      return; // Exit the function early
    }
    else{
      setIncomeValue(event.target.value)
    }
  }
  const updateOutcome = (event) => {
    const value = event.target.value;
    if (isNaN(value) || value.trim() === '' || Number(value) < 0) {
      alert(constants.alertNumericMsg);
      setOutcomeValue('')
      return; // Exit the function early
    }
    else{
      setOutcomeValue(event.target.value)
    }
  }

  const insertIncome = async () => {
    if(incomeValue === '' || incomeValue === undefined || incomeValue === 0 ){
      return false
    }
    const d = Date.now();
    const getDate = FormatDate(d)
    const selectedOption = dataIncome.find(option => option.id === selectedIncome);
    const roznamchaInValues = {
      payIn: parseInt(incomeValue),
      payOut: 0,
      date: getDate,
      description: `${incomeDescription}`,
      account: account
    }
    // }))
    console.log(roznamchaInValues)
    try {
      await db.set('RozNamchaBook', roznamchaInValues);
      setIncomeDescription('')
      setSelectedIncome(-1)
      setIncomeValue('')
      fetchData()
      // Show toast notification for success
      toast.success(constants.toastSuccessMsg, {
        position: 'top-center',
        autoClose: 300, // Close the toast after 3 seconds (optional)
      });
    } catch (error) {
      toast.error(constants.toastErrorMsg, {
        position: 'top-center',
        autoClose: 300, // Close the toast after 3 seconds (optional)
      });
    }
  }

  const insertOutcome = async () => {
    if(outcomeValue === '' || outcomeValue === undefined || outcomeValue === 0){
      return false
    }
    if (parseInt(outcomeValue) > parseInt(totalTodayRoznamcha)) {
      alert(`ددخل پیسي کمي دي`)
      return false
    }
    const d = Date.now();
    const getDate = FormatDate(d)
    const selectedOption = dataOutcome.find(option => option.id === selectedOutcome);
    console.log(selectedOption?.name)
    const roznamchaOutValues = {
      payIn: 0,
      payOut: parseInt(outcomeValue),
      date: getDate,
      description: `${outcomeDescription}`,
      account: account
    }
    console.log(roznamchaValues)
    try {
      await db.set('RozNamchaBook', roznamchaOutValues);
      setOutcomeDescription('')
      setSelectedOutcome(-1)
      setOutcomeValue('')
       fetchData()
      // Show toast notification for success
      toast.success(constants.toastSuccessMsg, {
        position: 'top-center',
        autoClose: 300, // Close the toast after 3 seconds (optional)
      });
    } catch (error) {
      toast.error(constants.toastErrorMsg, {
        position: 'top-center',
        autoClose: 300, // Close the toast after 3 seconds (optional)
      });
    }
  }

  const deleteRecord = async (inOut, id, rec) => {
    if(inOut === 1){
      console.log(parseInt(currRoznamchaOut)+ parseInt(rec.payIn) , parseInt(currRoznamchaIn))

    }
    if (parseInt(currRoznamchaOut) + parseInt(rec.payIn) > parseInt(currRoznamchaIn)) {
      alert(`ددخل پیسي کمي دي`)
      return false
    }
    console.log(id)
    try {
          await db.delete('RozNamchaBook', id);
    fetchData()
    toast.success(constants.toastSuccessMsg, {
      position: 'top-center',
      autoClose: 300, // Close the toast after 3 seconds (optional)
    });
    } catch (error) {
      toast.error(constants.toastErrorMsg, {
        position: 'top-center',
        autoClose: 300, // Close the toast after 3 seconds (optional)
      });
    }
  }

  const updateOutDesc = (event) => {
    console.log(event.target.value)
    // setOutDesShow(true)
    setOutcomeDescription(event.target.value) 
  }
  const updateInDesc = (event) => {
    console.log(event.target.value)
    // setOutDesShow(true)
    setIncomeDescription(event.target.value) 
  }
  const showInDesc = () => {
    setInDesShow(!inDesShow)
  }
  const showOutDesc = () => {
    setOutDesShow(!outDesShow)
  }

  const selAccount = (getRec) => {
    console.log(getRec.id)
    console.log(getRec.currency)
    setAccCurrency(getRec.currency)
    setAccount(getRec.id)
    setSelectedCurrencyId(getRec.id); // Update the selected currency ID
    // fetchData()
  }

  const activateApp = async() =>{
    console.log(otp)
    const getProfile = await db.getAll('Profile');
    console.log(getProfile)
    const payload = {mobile: getProfile[0].mobile, password: getProfile[0].password, otp: otp}
    console.log(payload)
    const d = Date.now();
    try {
      axios.post('https://roznamchaback.wasily.net/activate', payload).then(async response => {
        if(response.status === 200){
          setOtpErrorMsg('')
          const getDate = FormatDate(d)
          getProfile[0].months = 10//response.data
          const  makeProfile = {mobile:getProfile[0].mobile, password:getProfile[0].password,
            currencies:getProfile[0].currencies, months:response.data, activationDate:getDate, id:getProfile[0].id,
            netCheckIn:getProfile[0].netCheckIn, netCheckOut:getProfile[0].netCheckOut
          }
          await db.set('Profile', makeProfile);
          checkExpiry();
          navigate('/dashboard')
          console.log(getProfile[0])
        }
      }).catch(err=>{
        if(err.response.status === 404){
          setOtpErrorMsg(err.response.data.message)
      }
      })
    } catch (error) {
      
    }

  }
  const pushDataServer = async() => {
    // console.log(uploadData)
    const getProfile = await db.getAll('Profile');
    console.log(getProfile[0])
    const payload = {data:uploadData, user:getProfile}
        console.log(payload)
        axios.post('https://roznamchaback.wasily.net/upload', payload)
      .then(async response => {
        console.log(response)
        if(response.status === 200) {
          getProfile[0].netCheckIn = response.data.date
          await db.set('Profile', getProfile[0]);
        // setData(response.data);
        // navigate('/dashboard');
        const isoString = response.data.date;
        const date = new Date(isoString);
        // const formattedDate = format(date, 'MMMM dd, yyyy HH:mm:ss');
          
        setUploadDateTime(FormatDate(date))
        }
      })
  }
  return (
    <>
      <Grid item xs={12} sm={12 }>
      {expired ? 
        <>
        <Grid item xs={12} sm={12 }>
          <Grid item xs={12} sm={6} >
          <TextField
                        id="otp"
                        name="OTP"
                        label='OTP'
                        variant="outlined"
                        fullWidth
                        value={otp}
                        onChange={(e) => setOtp(e.target.value)}
                        margin="normal"
                      />
          </Grid>
          <Grid item >
                <Button variant="contained" onClick={activateApp}   >
                      Activate
                </Button>
                <br/>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12 }><label className='errorMsg'>{otpErrorMsg}</label></Grid>
        </>
        : null}
      <Grid className='uploadData' item xs={12} sm={6}> {/* Full width on extra-small screens, half width on small and up */}
        <Button 
          variant="contained" 
          color="primary" 
          onClick={pushDataServer} 
          fullWidth> 
          Upload
        </Button>
      </Grid>
      <Grid className='loggedOut' item xs={12} sm={6}> {/* Full width on extra-small screens, half width on small and up */}
        <Button 
          variant="contained" 
          color="secondary" 
          onClick={logout} 
          fullWidth> 
          Logout
        </Button>
      </Grid>
      </Grid>
      <br/>
      <Grid className='uploadDate' item xs={12} sm={matchesSm ? 12 : 6}>
        {uploadDateTime}
      </Grid>
      <Grid item xs={12} sm={matchesSm ? 12 : 6}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        value={selectedDate}
        onChange={handleDateChange}
        renderInput={(params) => (
          <TextField
            {...params}
            InputProps={{
              ...params.InputProps,
              readOnly: true, // Make input field read-only
              sx: {
                cursor: 'pointer', // Ensure the cursor shows a pointer when hovering over the input field
              },
            }}
            sx={{
              '& input': {
                cursor: 'default', // Prevent the cursor from appearing in the input field
              },
            }}
            onClick={() => params.inputProps.onClick()} // Ensure clicking on the field opens the calendar
          />
        )}
      />
    </LocalizationProvider>
      </Grid>
      <Grid item xs={12} sm={matchesSm ? 12 : 6}>
        {currency?.map((row, index) => (
          currency?.length > 1 ? 
          <Button 
            className={selectedCurrencyId === row.id ? 'selectedButton' : ''}
            key={index} 
            onClick={() => selAccount(row)} // Correctly pass the ID
          >
            {row?.currency}
          </Button>
          : null
        ))}
      </Grid>
      <Grid item xs={12} sm={matchesSm ? 12 : 6}>
        <div><p className='sumAmout'>{`${totalTodayRoznamcha}   ${accCurrency}  `}</p></div>
      </Grid>

      <div className='roznamcha' style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '10px' }}>

        <Grid item xs={12} sm={matchesSm ? 12 : 6}>
          <Paper>
            <table style={{ minWidth: 100 }} size="small" aria-label="first table">
              <thead>
                <tr>
                  <th>{constants.detailsOut}</th>
                  <th align="right">{accCurrency}</th>
                </tr>
              </thead>
              <tbody>
                {/* <tr>
                  <td>{constants.oldRoznamcha}</td>
                  <td>{oldRoznamchaOut}</td>
                </tr> */}
                {fetchRoznamcha.map((row, index) => (
                  row?.payOut != '' ?
                    <tr key={index}>
                      <td className='deleteIconHolder'> 
                      {noInsertion ? 
                      <div>
                          <DeleteIcon className='deleteIcon' onClick={()=>deleteRecord(0, row?.id, row)} />
                      </div>
                      : null }
                      <div>{row?.description}</div>
                      </td>
                      <td align="right">{row?.payOut}</td>
                    </tr>
                    : null
                ))}

                <tr className='lastLineSum'>
                  <td>{constants.outRoznamcha}</td>
                  <td>{currRoznamchaOut}</td>
                </tr>
                {noInsertion ?
                <>
                <tr>
                  <td colSpan={2}>
                    <div>
                      <a href="#" onClick={showOutDesc} style={{ fontSize: '14px', color: 'blue', textDecoration: 'underline' }}>
                        {constants.addingDescription}
                      </a>
                    </div>
                    <div>
                      {outDesShow ? 
                      <TextField
                        id="standard-multiline-static"
                        label={constants.description}
                        multiline
                        value={outcomeDescription}
                        rows={4}
                        onChange={(event)=>updateOutDesc(event)}
                        defaultValue={constants.description}
                      />
                      : null }
                      </div>
                  </td>
                </tr>
                
                  <tr className='bottomLine'>
                    <td>
                      <TextField
                        id="personName"
                        name="personName"
                        label={accCurrency}
                        variant="outlined"
                        fullWidth
                        value={outcomeValue}
                        onChange={updateOutcome}
                        margin="normal"
                      />
                      
                      </td>
                      <td>
                      {/* <Button endIcon={<SendIcon/>}>Send</Button> */}
                      {!expired ? 
                      <Button variant="contained" onClick={insertOutcome}   >
                      {constants.subtracting}
                      </Button>
                      : null}
                    </td>
                  </tr>
                  </>
                  : null}
              </tbody>

            </table>
          </Paper>
        </Grid>


        <Grid item xs={12} sm={matchesSm ? 12 : 6}>
          <Paper>
            <table style={{ minWidth: 100 }} size="small" aria-label="first table">
              <thead>
                <tr>
                  <th>{constants.detailsIn}</th>
                  <th align="right">{accCurrency}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{constants.oldRoznamcha}</td>
                  <td>{oldRoznamchaIn}</td>
                </tr>
                {fetchRoznamcha.map((row, index) => (
                    row?.payIn !== 0 ?
                    <tr key={index}>
                       
                      <td className='deleteIconHolder'>
                      {noInsertion ? 
                        <div className='deleteIcon'>
                          <DeleteIcon  onClick={()=>deleteRecord(1, row?.id, row)} />
                        </div>
                        : null }
                        <div>{row?.description}</div>
                      </td>
                      <td align="right">{row?.payIn}</td>
                    </tr>
                    : null
                ))}
                <tr className='lastLineSum'>
                  <td>{constants.inRoznamcha}</td>
                  <td>{currRoznamchaIn}</td>
                </tr>
                {noInsertion ?
                <tr>
                  <td colSpan={2}>
                  <div>
                      <a href="#" onClick={showInDesc} style={{ fontSize: '14px', color: 'blue', textDecoration: 'underline' }}>
                        {constants.addingDescription}
                      </a>
                    </div>
                    <div>
                      {inDesShow ? 
                      <TextField
                        id="standard-multiline-static"
                        label={constants.description}
                        multiline
                        value={incomeDescription}
                        rows={4}
                        onChange={(event)=>updateInDesc(event)}
                        defaultValue={constants.description}
                      />
                      : null }
                      </div>
                  </td>
                </tr>
                 : null }
                 {noInsertion ?
                  <tr className='bottomLine'>
                    <td>
                      <TextField
                        id="personName"
                        name="personName"
                        label={accCurrency}
                        variant="outlined"
                        fullWidth
                        value={incomeValue}
                        onChange={updateIncome}
                        margin="normal"
                      />
                      
                      </td>
                      <td>
                      {!expired ? <Button variant="contained" onClick={insertIncome}   >
                        {constants.adding}
                      </Button>
                      : null }
                    </td>
                  </tr>
                  : null}
              </tbody>
            </table>
          </Paper>
        </Grid>
      </div>
    </>
  );
};

export default Incoming;
