export default function swDev() {
     // Unregister all existing service workers
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.getRegistrations().then((registrations) => {
      console.log(registrations )
      for (let registration of registrations) {
        registration.unregister().then((boolean) => {
          console.log('Service Worker unregistered:', boolean);
        });
      }
    });
  }
  


    // const swUrl = `${process.env.PUBLIC_URL}/sw.js`;
    // navigator.serviceWorker.register(swUrl, { scope: '/' })
    // .then(registration => {
    //     console.log('Service Worker registered with scope:', registration.scope);
    // })
    // .catch(error => {
    //     console.error('Service Worker registration failed:', error);
    // });

  }

  if (navigator.serviceWorker.controller) {
    navigator.serviceWorker.getRegistrations().then((registrations) => {
      for (let registration of registrations) {
        console.log(registration)
        registration.update();
      }
    });
  }
  