import './App.css';
import Login from './component/login/Login.js'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Dashboard from './component/dashboard/Dashboard'
import CurrencyActivate from './component/currency/CurrencyActivate'
import Recover from './component/recover/Recover.js'
import Loader from "./Loader";
import { useEffect, useState } from 'react';
import axios from 'axios';
import db from './utils/Utility';

const idb =  window.indexedDB || window.mozIndexedDB || window.webkitIndexedDB || window.msIndexedDB || window.shimIndexedDB;
// const createCollectionInIndexedDB = () =>{
//   if(!idb){
//     console.log('Your Browser doesnt suppert Database')
//   }
//   else{
//     const request  = idb.open('roznamcha', 1);
//     request.onerror = (event) => {
//       console.log()
//       Swal.fire({
//         icon: 'error',
//         title: 'Error',
//         text: 'Your Mobile Browser Doesnt Support',
//       });
//     }
//   }
// }


function App() {
  const [loader, setLoader] = useState(false)
  const [deferredPrompt, setDeferredPrompt] = useState(null);
  

  // useEffect( ()=> {
  //   createCollectionInIndexedDB()
  // }, [])

  useEffect(() => {
    // Axios interceptor setup
    const requestInterceptor = axios.interceptors.request.use(config => {
      setLoader(true);
      return config;
    }, error => {
      setLoader(false);
      return Promise.reject(error);
    });

    const responseInterceptor = axios.interceptors.response.use(response => {
      setLoader(false);
      return response;
    }, error => {
      setLoader(false);
      return Promise.reject(error);
    });

    window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);

    // Clean up interceptors on unmount
    return () => {
      axios.interceptors.request.eject(requestInterceptor);
      axios.interceptors.response.eject(responseInterceptor);
      window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
    };
  }, []);
  const handleBeforeInstallPrompt = (event) => {
    // Prevent Chrome 67 and earlier from automatically showing the prompt
    event.preventDefault();
    console.log('app welcome', ' handlebeforeinstalprompt is running')
    // Stash the event so it can be triggered later
    setDeferredPrompt(event);
  };
  return (
    <div className="App">
      <header className="App-header">
        <Loader show={loader} />
        <Router>
          <Routes>
            {/* <Route path="/" element={<Home />} /> */}
            <Route path="/" element={<CurrencyActivate />} />
            <Route path="/currencyaccount" element={<CurrencyActivate/>} />
            <Route path="/login" element={<Login />} />
            <Route path="/dashboard" element={<Dashboard/>} />
            <Route path="/recover" element={<Recover/>} />
            {/* Add more routes as needed */}
          </Routes>
        </Router>
      </header>
    </div>
  );
}

export default App;
